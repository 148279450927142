import React, { useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { Link, navigate } from "gatsby";
import Swal from "sweetalert2";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import UserInput from "~/components/Users/UserInput";
import appointmentService from "~/utils/api/v1/appointmentService";
import insuranceService from "~/utils/api/v1/insuranceService";
import patientService from "~/utils/api/v1/patientService";
import { Patient } from "~/utils/interfaces/AppointmentPatient";


function EditUser({ id }: { id: string }): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [insurances, setInsurances] = useState<string[]>([]);
  const [patientData, setPatientData] = useState<Omit<Patient, "full_name">>({
    id: "",
    email: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    date_of_birth: "",
    gender: "male",
    document_type: "ci",
    document_number: "",
    insurance: "",
  });

  const fetchUserData = async (): Promise<void> => {
    setLoading(true);
    setError("");
    try {
      const patientRequest = await appointmentService.fetchPatient(id);
      const data: Patient = patientRequest.data.data;
      data.date_of_birth = data.date_of_birth?.substring(0, 10);
      setPatientData(data);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
        text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(
          err.response.data,
        )}`,
        confirmButtonText: "Continuar",
      });
    }
    setLoading(false);
  };

  const fetchInsurances = async (): Promise<void> => {
    setLoading(true);
    setError("");
    try {
      const insurancesRequest = await insuranceService.fetchInsurers();
      const data: string[] = insurancesRequest.data.data;
      setInsurances(data);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
        text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(
          err.response.data,
        )}`,
        confirmButtonText: "Continuar",
      });
    }
    setLoading(false);
  };

  const changePatientData = async (patientData: Patient, error: string): Promise<void> => {
    const params = new URLSearchParams(location.search);
    const appointmentId = params.get("appointment") ?? "";
    const appointmentPatientId = params.get("appointment_patient") ?? "";

    const newPatientId = error.split(", ")[2].split(")")[0];
    try {
      const newPatient = await patientService.detailsByIdentifier(newPatientId);
      await appointmentService.addPatient(appointmentId, newPatient.data.data.id);
      await patientService.deactivate(appointmentPatientId);
      navigate(`/appointment/${appointmentId}`);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
        text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(
          err.response.data,
        )}`,
        confirmButtonText: "Continuar",
      });
    }
    setLoading(false);
    fetchUserData();
  };

  const parseData = (patientData: Patient): Patient => {
    const data = patientData;
    const birthDate: Date = new Date(data.date_of_birth);
    birthDate.setHours(12);
    birthDate.setDate(birthDate.getDate() + 1);
    data.date_of_birth = birthDate.toISOString();
    return data;
  };

  const submitButton = async (): Promise<void> => {
    setLoading(true);
    try {
      const data = parseData(patientData);
      const request = await appointmentService.updatePatient(id, data);
      if (request.data.msg === "Patient updated") {
        await Swal.fire({
          icon: "success",
          title: "Paciente modificado exitosamente",
          confirmButtonText: "Continuar",
          didClose: () => {
            const params = new URLSearchParams(location.search);
            const return_url = params.get("return_url");
            if (return_url && return_url != "") {
              navigate(return_url);
            } else {
              navigate(`/patients`);
            }
          },
        });
      }
    } catch (err) {
      // Check duplicate user
      if (err.response.data.err.includes('duplicate key value violates unique constraint "unique_document"')) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "El paciente que intentas generar ya existe",
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Volver a editar",
          confirmButtonText: "Vincular el paciente correcto",
        }).then((result) => {
          if (result.isConfirmed) {
            // vincular paciente correcto
            changePatientData(patientData, err.response.data.err);
          } else {
            fetchUserData();
          }
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
          text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(
            err.response.data,
          )}`,
          confirmButtonText: "Continuar",
        });
        fetchUserData();
      }
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchInsurances();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <div className="max-w-xl mx-auto p-8">
          <h1 className="text-center mb-8">Editar paciente</h1>
          <UserInput
            userData={patientData}
            setUserData={setPatientData}
            insurances={insurances}
          />
          <div className="flex justify-end">
            <Link to={`/patients/${patientData.id}`}>
              <Button
                variant="text"
                color="primary"
                className="text-right m-5"
              >
                Volver
              </Button>
            </Link>
            <Button
              variant="contained"
              onClick={submitButton}
              color="primary"
              className="text-right m-5"
            >
              Guardar
            </Button>
          </div>
        </div>
      )}
    </PrivateRoute>
  );
}

export default EditUser;
